import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "preact/hooks";
import useAuth from "../../hooks/useAuth";
import { ptBR } from "date-fns/locale";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
function ModalAproveAccount({open, handleOpen, bsAccount}) {
  let { getApi,admPostApi,admGetApi } = useAuth();

  const [statusAccount, setStatusAccount] = React.useState();
    const [openDateAccount, setOpenDateAccount] = React.useState();
    const [additionalInformation, setAdditionalInformation] = React.useState();
    const [openDateAccountBool, setOpenDateAccountBool] = React.useState();

    const [isLoading, setIsLoading] = React.useState(false);



  const [statusAccountList, setStatusAccountList] = React.useState([]);

  const LoadAccountStatusList = async (page_arg=null, limit_arg=null) => {
    
    let response = await admGetApi("/list/business/status",{ });
    setStatusAccountList(response.lista);

    
  setStatusAccount(bsAccount.business_account_status_id);
  };
  

  
useEffect(() => {

  
  const lof=async ()=> {
    await LoadAccountStatusList();
  }

  lof();
  setOpenDateAccount(bsAccount.account_opening_date?new Date(bsAccount.account_opening_date):null);
  setOpenDateAccountBool(bsAccount.account_opening_date?true:false);
  setAdditionalInformation(bsAccount.additional_information)
 
},[open,bsAccount]);


const Update = async () => {
   
  setIsLoading(true);
  let response = await admPostApi("/bsaccount/update/"+bsAccount.id, {
    business_account_status_id:statusAccount,
    account_opening_date:openDateAccountBool?openDateAccount:null,
    account_opening_datebool:openDateAccountBool,
    additional_information:additionalInformation
    
  });
  alert("Dados atualizados com sucesso");
 
  setIsLoading(false);
  
};
  

  return (
    <React.Fragment>
     <Modal
        open={open}
        onClose={handleOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
      
          <Grid container spacing={3} >
          <Paper>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Atualização Cadastral
          </Typography>
      <Grid container sx={{overflow:"auto", width:600 }}>
        {bsAccount.fantasy_name &&
        <Grid item md={12}>
        <Table>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              ID
              </TableCell>
              <TableCell component="th" scope="row">
              {bsAccount.id}
              </TableCell>

          </TableRow>

          <TableRow >
            <TableCell component="th" scope="row">
              CPF/CNPJ
            </TableCell>
            <TableCell component="th" scope="row">
            {bsAccount.identifier} ( {bsAccount.account_type?bsAccount.account_type["name"]:null})
            </TableCell>
          </TableRow>

          <TableRow >
            <TableCell component="th" scope="row">
              Razão Social
            </TableCell>
            <TableCell component="th" scope="row">
            {bsAccount.company_name}
            </TableCell>
          </TableRow>

          <TableRow >
            <TableCell component="th" scope="row">
              Nome Fantasia
            </TableCell>
            <TableCell component="th" scope="row">
            {bsAccount.fantasy_name}
            </TableCell>
          </TableRow>
          <TableRow >
            <TableCell component="th" scope="row">
              Status Atual
            </TableCell>
            <TableCell component="th" scope="row">
            {bsAccount.account_status?bsAccount.account_status["name"]:null}
            </TableCell>
          </TableRow>

          <TableRow >
            <TableCell component="th" scope="row">
           Status da conta
            </TableCell>
            <TableCell component="th" scope="row">

            <FormControl fullWidth>
              <Select
                 labelId="demo-simple-select-label"
                 id="demo-simple-select"
                value={statusAccount}
                onChange={e=> setStatusAccount(e.target.value)} >
                {  statusAccountList.map(el => (
                    <MenuItem key={el.id+statusAccount} value={el.id} selected={el.id==statusAccount}>{el.extend_name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            </TableCell>
          </TableRow>

          <TableRow >
            <TableCell component="th" scope="row">
           Observação da conta
            </TableCell>
            <TableCell component="th" scope="row">

            <FormControl fullWidth>
              <TextareaAutosize 
                value={additionalInformation} 
                onChange={e=> setAdditionalInformation(e.target.value)} 
                minRows={5}> </TextareaAutosize>
            </FormControl>

            </TableCell>
          </TableRow>
         
          <TableRow >
            <TableCell component="th" scope="row">
            Data Aprovação da conta
            </TableCell>
            <TableCell component="th" scope="row">
              {
                openDateAccountBool &&
                
                <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    label="Data de Vencimento"
                    inputFormat="dd/MM/yyyy H:i:s"
                    onChange={setOpenDateAccount}
                    value={openDateAccount}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              }
                <Checkbox
                label={"Informar Data"}
                onClick={e=> setOpenDateAccountBool(!openDateAccountBool)}
                checked={openDateAccountBool} />


            </TableCell>
          </TableRow>
         

          
        </TableBody>
      </Table>
      <Button
      onClick={Update} variant="contained" disabled={isLoading}>Salvar</Button>
        </Grid>
        }
       
      </Grid>
    </Paper>
          </Grid>
          
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default ModalAproveAccount;
