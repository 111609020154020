import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import { setSession,setBusinessAccount } from "../utils/jwt";
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

// Note: If you're trying to connect JWT to your own backend, don't forget
// to remove the Axios mocks in the `/src/index.js` file.

const INITIALIZE = "INITIALIZE";
const INITIALIZEBS = "INITIALIZEBS";
const INITIALIZEBSBALANCE = "INITIALIZEBSBALANCE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";
const ShowModalInit = "ShowModalInit";
const Session_BusinessAccountId="@CacheBank_BusinessAccount"

const initialState = {
  isAuthenticated: false,
  isInitialized: false,

  isAuthenticatedBS: false,
  isInitializedBS: false,
  user: [],
  businessAccount:[],

  isBSAccount:false,

  
  balance_available:null,
  balance_toreceive:null,
  balance_blocked:null,
  balance_updated_at:null,
  balance_receveid_month:null,



  
  showModal:null,

};


const URLBASE=process.env.REACT_APP_URL_API;
const URLBASEADM=process.env.REACT_APP_URL_BASE_ADM;

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isAuthenticatedBS:action.payload.isAuthenticatedBS?action.payload.isAuthenticatedBS:false,
        isInitialized: true,
        user: action.payload.user,
        businessAccount:action.payload.businessAccount
      };
    case INITIALIZEBS:
        return {
          ...state,
          isAuthenticatedBS:action.payload.isAuthenticatedBS,
          isInitializedBS:true,
          isBSAccount:action.payload.isBSAccount
    };
    case INITIALIZEBSBALANCE:
      return {
        ...state,
        balance_available:action.payload.balance_available,
        balance_toreceive:action.payload.balance_toreceive,
        balance_blocked:action.payload.balance_blocked,
        balance_updated_at:action.payload.balance_updated_at,
        balance_receveid_month:action.payload.balance_receveid_month,
  };

    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case ShowModalInit:
        return {
          ...state,
          showModal:action.payload.showModal
    };
    default:
      return {
        ...state
      };
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  useEffect(() => {
    const initialize = async () => {
      checkAuth();
    };

    initialize();
  }, []);

  





  const checkAuth = async()=>{
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      const CacheBank_BusinessAccount = window.localStorage.getItem(Session_BusinessAccountId);

      if (CacheBank_BusinessAccount) {
        setBusinessAccount(CacheBank_BusinessAccount);
      }

      if (accessToken) {
        setSession(accessToken);
        let userValid=false;
        const response = await axios.get(URLBASE+"/system_adm");
        if(response){
          userValid=response.data.system_adm?true:false
        }
        
        const { system_adm,business_account } = response.data;


        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: userValid,
            user:system_adm
          },
        });
      } else {
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            isAuthenticatedBS:false,
            user: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }

  const signIn = async (email, password) => {
    const response = await axios.post(URLBASE+"/user/auth", {
      email,
      password,
    });
    if(response){
      const { token, bsaccounts } = response.data;


  
  
      setSession(token);
    }else{
      return response;
    }

    
   
  };
  const ShowModalFunc = async (modal) => {
    dispatch({
      type: ShowModalInit,
      payload: {
        showModal:modal,
      },
    });
  };


  const signOut = async () => {
    const response = await axios.post(URLBASE+"/user/auth/logout",{});
    if(response){
      const { success} = response.data;

      return success;
    }else{
      return response;
    }

    

    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (fullname, email, password, phone) => {
    let responsedata=null;

    const submitAxios = await axios.post(URLBASE+"/signup", {
      fullname,
      email,
      password,
      phone,
    }).catch(error => {
      if (error) {
        console.log(error)
        responsedata=error;
      }
    });
    if(submitAxios){
      responsedata=submitAxios.data;

      const { api_token, user } = submitAxios.data;

      window.localStorage.setItem("accessToken", api_token);
    }
   
    return responsedata;
  };

  const signUpStep2 = async (url,data) => {
    let responsedata=null;

    const submitAxios = await axios.post(URLBASE+url, data ).catch(error => {
      if (error) {
        console.log(error)
        responsedata=error;
      }
    });

    if(submitAxios){
      responsedata=submitAxios.data;

      const { general_business_account_id } = submitAxios.data;

      window.localStorage.setItem(Session_BusinessAccountId, general_business_account_id);
     
    }
   
    return responsedata;
  };

  


  const getApi = async (url,data) => {
    let responsedata=null;

    const accessToken = window.localStorage.getItem("accessToken");
    const CacheBank_BusinessAccount = window.localStorage.getItem(Session_BusinessAccountId);

    if (accessToken) {
      setSession(accessToken);
    }
    if (CacheBank_BusinessAccount) {
      setBusinessAccount(CacheBank_BusinessAccount);
    }

    const submitAxios = await axios.get(URLBASE+url, data ).catch(error => {
      if (error) {
        console.log(error)
        responsedata=error;
      }
    });
    if(submitAxios){
      responsedata=submitAxios.data;
    }
   
    return responsedata;
  };


  const deleteApi = async (url,data) => {
    let responsedata=null;
    console.log(data)

    const accessToken = window.localStorage.getItem("accessToken");
    const CacheBank_BusinessAccount = window.localStorage.getItem(Session_BusinessAccountId);

    if (accessToken) {
      setSession(accessToken);
    }
    if (CacheBank_BusinessAccount) {
      setBusinessAccount(CacheBank_BusinessAccount);
    }


    const submitAxios = await axios.delete(URLBASE+url, data ).catch(error => {
      if (error) {
        console.log(error)
        responsedata=error;
      }
    });
    if(submitAxios){
      responsedata=submitAxios.data;
    }
   
    return responsedata;
  };


  const postApi = async (url,data) => {
    let responsedata=null;
    console.log(data)

    const accessToken = window.localStorage.getItem("accessToken");
    const CacheBank_BusinessAccount = window.localStorage.getItem(Session_BusinessAccountId);

    if (accessToken) {
      setSession(accessToken);
    }
    if (CacheBank_BusinessAccount) {
      setBusinessAccount(CacheBank_BusinessAccount);
    }


    const submitAxios = await axios.post(URLBASE+url, data ).catch(error => {
      if (error) {
        console.log(error)
        responsedata=error;
      }
    });
    if(submitAxios){
      responsedata=submitAxios.data;
    }
   
    return responsedata;
  };



  
  const admPostApi = async (url,data) => {
    let responsedata=null;
    console.log(data)

    const accessToken = window.localStorage.getItem("accessToken");
    const CacheBank_BusinessAccount = window.localStorage.getItem(Session_BusinessAccountId);

    if (accessToken) {
      setSession(accessToken);
    }
    if (CacheBank_BusinessAccount) {
      setBusinessAccount(CacheBank_BusinessAccount);
    }


    const submitAxios = await axios.post(URLBASEADM+url, data ).catch(error => {
      if (error) {
        console.log(error)
        responsedata=error;
      }
    });
    if(submitAxios){
      responsedata=submitAxios.data;
    }
   
    return responsedata;
  };

  const admPutApi = async (url,data) => {
    let responsedata=null;
    console.log(data)

    const accessToken = window.localStorage.getItem("accessToken");
    const CacheBank_BusinessAccount = window.localStorage.getItem(Session_BusinessAccountId);

    if (accessToken) {
      setSession(accessToken);
    }
    if (CacheBank_BusinessAccount) {
      setBusinessAccount(CacheBank_BusinessAccount);
    }


    const submitAxios = await axios.put(URLBASEADM+url, data ).catch(error => {
      if (error) {
        console.log(error)
        responsedata=error;
      }
    });
    if(submitAxios){
      responsedata=submitAxios.data;
    }
   
    return responsedata;
  };



  const admGetApi = async (url,data) => {
    let responsedata=null;

    const accessToken = window.localStorage.getItem("accessToken");
    const CacheBank_BusinessAccount = window.localStorage.getItem(Session_BusinessAccountId);

    if (accessToken) {
      setSession(accessToken);
    }
    if (CacheBank_BusinessAccount) {
      setBusinessAccount(CacheBank_BusinessAccount);
    }

    const submitAxios = await axios.get(URLBASEADM+url, data ).catch(error => {
      if (error) {
        console.log(error)
        responsedata=error;
      }
    });
    if(submitAxios){
      responsedata=submitAxios.data;
    }
   
    return responsedata;
  };




  const resetPassword = async (email) => {
    const response = await axios.post(URLBASE+"/user/auth/reset/generatecode", {
      email
    });
    if(response){
      const { success} = response.data;

      return success;
    }else{
      return response;
    }

    
   
  };

  
  const resetPasswordStep2 = async ( email,password,token) => {
    const response = await axios.post(URLBASE+"/user/reset/password", {
      email,password,token
    });
    if(response){
      const { success } = response.data;


      if (success) {
      }
  
  
    }else{
      return response;
    }
    
   
  };



  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        resetPassword,
        resetPasswordStep2,
        getApi,
        signUpStep2,
        postApi,
        admPostApi,
        admGetApi,
        admPutApi,
        deleteApi,
        ShowModalFunc
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
