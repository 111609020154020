import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Avatar, Paper, Typography } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignInComponent from "../../components/auth/SignIn";



const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const BigAvatar = styled(Avatar)`
  width: 92px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
`;

function SignIn() {
  return (
    <React.Fragment>
      
      <img src="https://pubstore1.cachebank.com.br/logos/v2/Logomarca-%20Azul.png" width="180" />
      <Wrapper>
        <Helmet title="Sign In" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Portal Administrativo
        </Typography>


        <Typography component="h2" variant="body1" align="center">
          Para continuar, informe os seus dados de acesso abaixo.
        </Typography>

        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
