import {
  BookOpen,
  Briefcase,
  Calendar,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  ShoppingCart,
  PieChart,
  Sliders,
  Users,
  Filter,
  FileMinus,
  DollarSign,
} from "react-feather";

const pagesSection = [
  {
    href: "/app",
    icon: Sliders,
    title: "DashBoard"
  },
  {
    href: "#",
    icon: Layout,
    title: "Contas de Negócios",
    children: [
      {
        href: "/digital-account/bs-account",
        title: "Lista de Contas",
      },
      {
        href: "/digital-account/bs-account/lista/pendentes",
        title: "Contas Pendentes",
      },
      {
        href: "#",
        title: "Operadores",
      }
    ],
  },
  {
    href: "#",
    icon: DollarSign,
    title: "Cobranças",
    children: [
      {
        href: "#",
        title: "Todas cobranças",
      },
      {
        href: "#",
        title: "Consultar Boleto",
      },
      {
        href: "#",
        title: "Consultar Pix",
      }
    ]
  },
  
  {
    href: "#",
    icon: DollarSign,
    title: "Transferências",
    children: [
      {
        href: "/cobrancas",
        title: "Todas transferências",
      }
    ]
  },
  {
    href: "#",
    icon: DollarSign,
    title: "Movimentações Fin.",
    children: [
      {
        href: "/cobrancas",
        title: "Movimentações",
      }
    ]
  }];
  const MeuDinheiroSection = [
  {
    href: "/digital-account/conformidade/consulta-cpf-cnpj",
    icon: Filter,
    title: "Consulta de CPF/CNPJ"
  },
  {
    href: "#",
    icon: Filter,
    title: "Lista Restritiva",
    children: [
      {
        href: "/digital-account/conformidade/lista-restritiva",
        title: "Consultar",
      }
    ]
  },
  {
    href: "#",
    icon: FileMinus,
    title: "Documentações",
    children: [
      {
        href: "#",
        title: "Listagem",
      },
      {
        href: "#",
        title: "Pendentes",
      },
    ]
  }
];

const AtendimentoMNU = [

  {
    href: "#",
    icon: Filter,
    title: "Tickets",
    children: [
      {
        href: "/digital-account/tickets",
        title: "Listagem",
      }
    ]
  }
];



const configContaSection = [
  {
    href: "#",
    icon: Grid,
    title: "Minha Conta",
    children: [
      {
        href: "/minha-conta/meus-dados",
        title: "Dados da conta",
      },
      {
        href: "/minha-conta/detalhes",
        title: "Detalhes da Conta",
      },
      {
        href: "/components/avatars",
        title: "Tipo Conta",
      },
      {
        href: "/minha-conta/documentos",
        title: "Meus Documentos",
      }
    ],
  },
];


const FerramentasModulosSection = [
  {
    href: "/components",
    icon: Grid,
    title: "Automação",
    children: [
      {
        href: "/automacao/email",
        title: "Automação via E-mail",
      },
      {
        href: "/automacao/sms",
        title: "Automação via SMS",
      },
      {
        href: "/automacao/whatsapp",
        title: "Automação via Whatsapp",
      },
      {
        href: "/automacao/robo-voz",
        title: "Automação via Robô de voz",
      },
      {
        href: "/automacao/enviadas",
        title: "Automações enviadas",
      }
    ],
  },
  {
    href: "/components",
    icon: Grid,
    title: "Portal do cliente",
    children: [
      {
        href: "/components/alerts",
        title: "Dados da conta",
      },
      {
        href: "/components/accordion",
        title: "Taxas",
      },
      {
        href: "/components/avatars",
        title: "Tipo Conta",
      },
      {
        href: "/components/badges",
        title: "Meus Documentos",
      }
    ],
  },
  {
    href: "/components",
    icon: Grid,
    title: "Integração API",
    children: [
      {
        href: "/components/alerts",
        title: "Chaves de Acesso",
      }
    ],
  },
];

const AtendimentoAoClienteSection = [
  {
    href: "/components",
    icon: Grid,
    title: "Solicitações",
    children: [
      {
        href: "/components/alerts",
        title: "Abrir uma nova",
      },
      {
        href: "/components/accordion",
        title: "Ver solicitações",
      }
    ],
  },
  {
    href: "/components",
    icon: Grid,
    title: "Meu Consultor"
  }
];


const navItems = [
  {
    title: "DashBoard",
    pages: pagesSection,
  },
  
  {
    title: "Conformidades",
    pages: MeuDinheiroSection,
  },
  
  {
    title: "Atendimento",
    pages: AtendimentoMNU,
  }
];

export default navItems;
