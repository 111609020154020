import React, { useEffect,useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  LinearProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import InputMask from "react-input-mask";
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);




function DataGridClientes() {
let { getApi,admPostApi,admGetApi } = useAuth();
const [listCobr, setListCobr] = React.useState([]);
const [loadingList, setLoadingList] = React.useState(true);





const [page, setPage] = useState(0);
const [limitPage, setLimitPage] = useState(5);
const [totalRecords, setTotalRecords] = useState(0);



const [arrayResult, setArrayResult] = React.useState([]);

const [authenticating, setAuthenticating] = React.useState(false);



const navigate = useNavigate();


const columns = [
  { field: "id", headerName: "ID", width: 120,
  renderCell: (params: GridRenderCellParams<Date>)  => (
    <React.Fragment>
       <Link
       href={"bs-account/aprovacao/"+params.row.id}>
          {params.row.id?params.row.id:null}
        </Link>
    </React.Fragment>
  
      
  ) },
  {
    field: "company_name",
    headerName: "Razão Social",
    sortable: false,
    width: 200,
  },
  {
    field: "fantasy_name",
    headerName: "Nome Fantasia",
    sortable: false,
    width: 200,
  },
  {
    field: "identifier",
    headerName: "CPF/CNPJ",
    sortable: false,
    width: 140,
  },
  {
    field: "document_identification",
    headerName: "RG/CNH",
    sortable: false,
    width: 100
  },
  {
    field: "document_selfie",
    headerName: "Selfie",
    sortable: false,
    width: 100
  },
  {
    field: "document_pj",
    headerName: "Contrato",
    sortable: false,
    width: 100
  },
  {
    field: "status_name",
    headerName: "Status",
    sortable: false,
    width: 140
  },
  {
    field: "consultant",
    headerName: "Consultor",
    sortable: false,
    width: 140
  },
  
  {
    field: "action",
    headerName: "Ação",
    sortable: false,
    width: 150,
    renderCell: (params: GridRenderCellParams<Date>)  => (
      
      <React.Fragment>
        <Link  href={"/digital-account/bs-account/aprovacao/"+params.row.id}>
        <Button
          variant="contained"
          size="small"
          tabIndex={params.hasFocus ? 0 : -1}
         >
          Visualizar
        </Button>
        </Link>
        <Link>
        <Button
          variant="contained"
          size="small"
          tabIndex={params.hasFocus ? 0 : -1}
          onClick={e=> AuthAsUser(params.row.id)}
          disabled={authenticating}
         >
          Auth
        </Button>
        </Link>
      </React.Fragment>
    
        
    ),
  },
];
const AuthAsUser = async (general_business_account_id) => {
  setAuthenticating(true);
  let response = await admPostApi("/bsaccount/auth",{ 
    general_business_account_id:general_business_account_id
  });

  window.open(response.url, '_blank').focus();
  setAuthenticating(false);
return;

};


const [activityList, setAcitivtyList] = useState([]);
const [listMonthlyBilling, setListMonthlyBilling] = useState([]);

const [listAccountType, setListAccountType] = useState([]);


const [search_Status, setSearchStatus] = useState(-1);
const [searchText, setSearchText] = useState(null);
const [query_type, setQueryType] = useState("company_name");
const [query_filterDateType, setQuery_filterDateType] = useState(1);

const [query_filterDateStart, setQuery_filterDateStart] = useState(null);
const [query_filterDateEnd, setQuery_filterDateEnd] = useState(null);


const [accountType, setAccountType] = useState(-1);
const [activity, setActivity] = useState(-1);
const [monthlyBilling, setMonthlyBilling] = useState(-1);

const [documentStatus, setDocumentStatus] = useState(-1);


function LoadQueryParams(limit_arg,page_arg){

  let bsQuery={};
  
  bsQuery["limit"]=limit_arg;
  bsQuery["page"]=page_arg;
  bsQuery["minimal_data"]=false;

  bsQuery["queryString"]={
    "BusinessAccount":{
      "filterdatevalue":{}
    },
    "OperatorsUser":{},
    "BusinessAccountable":{},
    "RequestedDocumentStatus":[{}]
  };

    bsQuery["queryString"]["BusinessAccount"]['status_id']=1;
  


  if(['company_name','fantasy_name','identifier','id'].includes(query_type) ){
    bsQuery["queryString"]["BusinessAccount"][query_type]=searchText;
  }


  if(['company_name','fantasy_name','identifier','id'].includes(query_type) ){
    bsQuery["queryString"]["BusinessAccount"][query_type]=searchText;
  }

  if(['operator_name'].includes(query_type) ){
    bsQuery["queryString"]["OperatorsUser"]['fullname']=searchText;
  }

  if(['operator_email'].includes(query_type) ){
    bsQuery["queryString"]["OperatorsUser"]['email']=searchText;
  }

  if(['bsaccountable_fullname'].includes(query_type) ){
    bsQuery["queryString"]["BusinessAccountable"]['fullname']=searchText;
  }
  if(['bsaccountable_identifier'].includes(query_type) ){
    bsQuery["queryString"]["BusinessAccountable"]['identifier']=searchText;
  }
  if(['bsaccountable_phone'].includes(query_type) ){
    bsQuery["queryString"]["BusinessAccountable"]['phone']=searchText;
  }
  if(['bsaccountable_email'].includes(query_type) ){
    bsQuery["queryString"]["BusinessAccountable"]['email']=searchText;
  }
  

  if(accountType!=-1){
    bsQuery["queryString"]["BusinessAccount"]["account_type_id"]=accountType;
  }

  if(activity!=-1){
    bsQuery["queryString"]["BusinessAccount"]["activity_id"]=activity;
  }

  if(monthlyBilling!=-1){
    bsQuery["queryString"]["BusinessAccount"]["monthly_billing_id"]=monthlyBilling;
  }

  if(query_filterDateType==1 && ( query_filterDateStart || query_filterDateEnd )){
    bsQuery["queryString"]["BusinessAccount"]["filterdate"]=query_filterDateType;

    bsQuery["queryString"]["BusinessAccount"]["filterdatevalue"]["start"]=query_filterDateStart;
    bsQuery["queryString"]["BusinessAccount"]["filterdatevalue"]["end"]=query_filterDateEnd;
  }
  
  if(documentStatus==0){
    

    bsQuery["queryString"]["RequestedDocumentStatus"]=[
      {"document_type_id": 1,
      "documents_status_id":6},
      {"document_type_id": 2,
      "documents_status_id":6},
      {"document_type_id": 3,
      "documents_status_id":6}
    ];
  }else if(documentStatus==1){
    
    bsQuery["queryString"]["RequestedDocumentStatus"]=[
      {"document_type_id": 1,
      "documents_status_id":0},
      {"document_type_id": 2,
      "documents_status_id":0},
      {"document_type_id": 3,
      "documents_status_id":0}
    ];
  }else if(documentStatus==2){
    
    bsQuery["queryString"]["RequestedDocumentStatus"]=[
      {"document_type_id": 1,
      "documents_status_id":0}
    ];
  }else if(documentStatus==3){
    
    bsQuery["queryString"]["RequestedDocumentStatus"]=[
      {"document_type_id": 2,
      "documents_status_id":0}
    ];
  }else if(documentStatus==4){
    
    bsQuery["queryString"]["RequestedDocumentStatus"]=[
      {"document_type_id": 3,
      "documents_status_id":0}
    ];
  }
  else if(documentStatus==5){
    
    bsQuery["queryString"]["RequestedDocumentStatus"]=[
      {"document_type_id": 1,
      "documents_status_id":6}
    ];
  }else if(documentStatus==6){
    
    bsQuery["queryString"]["RequestedDocumentStatus"]=[
      {"document_type_id": 2,
      "documents_status_id":6}
    ];
  }else if(documentStatus==7){
    
    bsQuery["queryString"]["RequestedDocumentStatus"]=[
      {"document_type_id": 3,
      "documents_status_id":6}
    ];
  }else if(documentStatus==8){
    

    bsQuery["queryString"]["RequestedDocumentStatus"]=[
      {"document_type_id": 1,
      "documents_status_id":7},
      {"document_type_id": 2,
      "documents_status_id":7},
      {"document_type_id": 3,
      "documents_status_id":7}
    ];
  }


  return bsQuery;

}

useEffect(() => {

  LoadActivityList();
  LoadMonthlyBillList();
  LoadAccountTypeList();
  LoadMyClients(0, 5);
},[]);

const LoadActivityList = async (page_arg=null, limit_arg=null) => {
    
  let response = await admGetApi("/list/business/activity",{ });
  setAcitivtyList(response.lista)
};

const LoadMonthlyBillList = async (page_arg=null, limit_arg=null) => {
    
  let response = await admGetApi("/list/business/monthly_billing",{ });
  setListMonthlyBilling(response.lista)
};

const LoadAccountTypeList = async (page_arg=null, limit_arg=null) => {
    
  let response = await admGetApi("/list/business/type",{ });
  setListAccountType(response.lista)
};





const LoadMyClients = async (page_arg=null, limit_arg=null) => {
    
  setListCobr([]);
  setLoadingList(true);
  console.log(page_arg)
  let Arrayp=LoadQueryParams(limit_arg!=null?limit_arg:limitPage,page_arg!=null?(page_arg==0?page_arg:page_arg):(page==0?page:page));
  let response = await admPostApi("/bsaccount/list", Arrayp);
  console.log(response.lista)
  
  setPage(response.page);
  setLimitPage(response.limit);
  setTotalRecords(response.total)
  FillArrayResult(response.lista.map(x=> x.business_account));
  setLoadingList(false);

};

const FillArrayResult=(data)=>{
  let arrayTMP=[];
  data.forEach(function(curr, index, array){
    
    let requested_documents=curr["requested_documents"];
    let documentIdentificationArr=requested_documents.find(x=> x.document_type["id"]==1);
    let documentSelfieArr=requested_documents.find(x=> x.document_type["id"]==2);
    let documentPJArr=requested_documents.find(x=> x.document_type["id"]==3);
    



    arrayTMP.push({
      id:curr["id"],
      company_name:curr["company_name"],
      fantasy_name:curr["fantasy_name"],
      identifier:curr["identifier"],
      account_type_name:curr["account_type"]?curr["account_type"]["id"]==1?"PF":"PJ":"Não definido",

      monthly_billing:curr["monthly_billing"]?( parseInt(curr["monthly_billing"]["from"])+"-"+parseInt(curr["monthly_billing"]["up_to"]) ):"Não definido",
      activity_name:curr["activity"]?curr["activity"]["name"]:"Não definido",
      operator_main_name:curr["operator"]?curr["operator"]["fullname"]:"Não definido",
      operator_main_email:curr["operator"]?curr["operator"]["email"]:"Não definido",
      status_name:curr["account_status"]?curr["account_status"]["name"]:"Não definido",

      document_identification:documentIdentificationArr?documentIdentificationArr["status"]["name"]:"Não definido",
      document_selfie:documentSelfieArr?documentSelfieArr["status"]["name"]:"Não definido",
      document_pj:documentPJArr?documentPJArr["status"]["name"]:"Não definido",
      consultant:curr["consultant"]?curr["consultant"]:"Não definido",
    })
    setListCobr(arrayTMP)
  });

}


  return (
    <Card mb={6}>
      <CardContent pb={1}>
  
       
      </CardContent>
      <Typography variant="h6" gutterBottom>
          Procurar
        </Typography>
            <Grid container spacing={6}>
                <Grid item md={6}>
                  <TextField
                    id="query"
                    placeholder={"Campo de busca"}
                    variant="outlined"
                    fullWidth
                    my={2}
                    type="text"
                    value={searchText}
                    onChange={e=> setSearchText(e.target.value)}
                  />
                </Grid>
                <Grid item md={4}>
                  
                <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Tipo de pesquisa</InputLabel>
                      <Select fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={"Tipo de pesquisa"}
                        value={query_type}
                        onChange={e => setQueryType(e.target.value)}>
                          <MenuItem value={"company_name"}>Razão Social</MenuItem>
                          <MenuItem value={"fantasy_name"}>Nome Fantasia</MenuItem>
                          <MenuItem value={"identifier"}>CPF/CNPJ da empresa</MenuItem>
                          <MenuItem value={"id"}>Código da conta de negócio</MenuItem>
                          <MenuItem value={"operator_name"}>Nome do Operador</MenuItem>
                          <MenuItem value={"operator_email"}>E-mail do Operador</MenuItem>
                          <MenuItem value={"bsaccountable_fullname"}>Nome do Sócio</MenuItem>
                          <MenuItem value={"bsaccountable_identifier"}>CPF/CNPJ do Sócio</MenuItem>
                          <MenuItem value={"bsaccountable_email"}>E-mail do Sócio</MenuItem>
                          <MenuItem value={"bsaccountable_phone"}>Telefone do Sócio</MenuItem>
                      </Select>
                    </FormControl>
                   
                </Grid>
              </Grid>


              <Grid container spacing={6}>
                <Grid item md={2}>
                    <FormControl fullWidth  >
                    <InputLabel id="demo-simple-select-label">{"Tipo de Conta"}</InputLabel>
                      <Select fullWidth required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Tipo de Conta"
                        value={accountType}
                        onChange={e=> setAccountType(e.target.value)}
                      >
                      <MenuItem value={-1}>Todos </MenuItem>
                      {  listAccountType.map(el => (
                        <MenuItem value={el.id}>{el.name}</MenuItem>
                      ))}
                      </Select>
                    </FormControl>
                </Grid>
                <Grid item md={2}>
                    <FormControl fullWidth  >
                    <InputLabel id="demo-simple-select-label">{"Atividade Principal"}</InputLabel>
                      <Select fullWidth required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Status do pagamento Pagamento"
                        value={activity}
                        onChange={e=> setActivity(e.target.value)}
                      >
                      <MenuItem value={-1}>Todos as atividades </MenuItem>
                      <MenuItem value={0}>Não definido</MenuItem>
                      {  activityList.map(el => (
                        <MenuItem value={el.id}>{el.name}</MenuItem>
                      ))}
                      </Select>
                    </FormControl>
                </Grid>

                <Grid item md={2}>
                    <FormControl fullWidth  >
                    <InputLabel id="demo-simple-select-label">{"Faturamento Estimado"}</InputLabel>
                      <Select fullWidth required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Status do pagamento Pagamento"
                        value={monthlyBilling}
                        onChange={e=> setMonthlyBilling(e.target.value)}
                      >
                      <MenuItem value={-1}>Todos</MenuItem>
                      <MenuItem value={0}>Não definido </MenuItem>
                      {  listMonthlyBilling.map(el => (
                        <MenuItem value={el.id}>{el.name}</MenuItem>
                      ))}
                      </Select>
                    </FormControl>
                </Grid>

                <Grid item md={2}>
                    <FormControl fullWidth  >
                    <InputLabel id="demo-simple-select-label">{"Status Documentos"}</InputLabel>
                      <Select fullWidth required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Status Documentos"
                        value={documentStatus}
                        onChange={e=> setDocumentStatus(e.target.value)}
                      >
                      <MenuItem value={-1}>Todos </MenuItem>
                      <MenuItem value={0}>Todos documentos a análisar</MenuItem>
                      <MenuItem value={8}>Todos documentos aprovados</MenuItem>
                      <MenuItem value={1}>Todos documentos pendentes</MenuItem>
                      <MenuItem value={2}>RG Pendente Envio</MenuItem>
                      <MenuItem value={3}>Selfie Pendente Envio</MenuItem>
                      <MenuItem value={4}>Contrato Pendente Envio</MenuItem>
                      
                      <MenuItem value={5}>RG a análisar</MenuItem>
                      <MenuItem value={6}>Selfie a análisar</MenuItem>
                      <MenuItem value={7}>Contrato a análisar</MenuItem>
                        
                      </Select>
                    </FormControl>
                </Grid>

              </Grid>

                <Grid container spacing={6}>
                <Grid item md={2}>
                    <FormControl fullWidth  >
                    <InputLabel id="demo-simple-select-label">{"Filtrar por data"}</InputLabel>
                      <Select fullWidth required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Filtrar por data"
                        value={query_filterDateType}
                        onChange={e=> setQuery_filterDateType(e.target.value)}
                      >
                      <MenuItem value={1}>Data de abertura </MenuItem>
                      </Select>
                    </FormControl>
                </Grid>
                <Grid item md={2}>
                  <TextField
                    id="query"
                    label={"Data Inicial"}
                    variant="outlined"
                    fullWidth
                    type="date"
                    focused={true}
                    onChange={e=> setQuery_filterDateStart(e.target.value)}
                    value={query_filterDateStart}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    id="query"
                    label={"Data Final"}
                    variant="outlined"
                    fullWidth
                    type="date"
                    focused={true}
                    onChange={e=> setQuery_filterDateEnd(e.target.value)}
                    value={query_filterDateEnd}
                  />
                </Grid>
              </Grid>
              <Button variant="contained"  color="primary" mt={3} onClick={e => LoadMyClients(0)}>
                Pesquisar
              </Button>
              

              <Divider my={6} />
      <Paper>
        <div style={{ height: (limitPage==5?400:600), width: "100%" }}>
     

          <DataGrid
            rowsPerPageOptions={[5, 10, 25]}
            rows={listCobr}
            columns={columns}
            pageSize={limitPage}
            rowCount={totalRecords}
            page={page}
            onPageSizeChange={e  => {LoadMyClients(null, e)  } }
            paginationMode={'server'}
            onPageChange={e  => {LoadMyClients(e, null)  } }
            loading={loadingList}
            
          />
          

        </div>
      </Paper>
    </Card>
  );
}

function DataGridPage() {
  const [open, setOpen] = React.useState(false);
const [selectedValue, setSelectedValue] = React.useState([]);

const handleClose = (value) => {
  ShowModalFunc(null)
};

let { getApi,postApi,ShowModalFunc,showModal,isInitializedBS } = useAuth();




  return (
    <React.Fragment>
      <Helmet title="Contas de Pendentes" />
      <Grid justifyContent="space-between" container spacing={10}>
      <Grid item>
      <Typography variant="h3" gutterBottom display="inline">
        Contas Pendentes
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/cobrancas">
        Contas de Negócios

        </Link>
        <Typography>Pendentes</Typography>
      </Breadcrumbs>
      </Grid>
      
      
        </Grid>


      <Divider my={6} />
  
      <DataGridClientes />
     

       
       
    </React.Fragment>
  );
}

export default DataGridPage;
