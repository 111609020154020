import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function SignIn() {
  const navigate = useNavigate();
  const { signIn } = useAuth();

  let { resetedPassword } = useParams();


  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("O e-mail deve ser válido")
          .max(255)
          .required("E-mail deve ser preenchido"),
        password: Yup.string().max(255).required("A senha deve ser preenchida."),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await signIn(values.email, values.password);
          window.location.href="/digital-account";
        } catch (error) {
          console.log(error);
          const message = error.message["error"] || "Um erro ocorreu. Por favor, tente novamente.";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
         
            {resetedPassword && !errors.submit ?
            (
             <React.Fragment>
               <Alert mt={3} mb={3} severity="success">
               Sua senha foi resetada com sucesso.
              </Alert>
             </React.Fragment>
              ):(
                <Alert mt={3} mb={3} severity="info">
                <React.Fragment>
                Use <strong>seuemail@email.com</strong> e {" "}
               <strong>sua senha</strong> para logar.
              </React.Fragment>
              
          </Alert>
          )
          }
           
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label="Seu e-mail"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type="password"
            name="password"
            label="Sua Senha"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
         { /*<FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Lembrar"
          />*/}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Autenticar
          </Button>
          <Button
            component={Link}
            to="/reset-password"
            fullWidth
            color="primary"
          >
            Esqueci a senha
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
