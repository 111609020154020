import React, { useEffect,useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  LinearProgress,
  TextareaAutosize,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import InputMask from "react-input-mask";
import Tiptap from "../../../Tiptap";



const Divider = styled(MuiDivider)(spacing);



function TicketViewReply(props) {
  let {body,message, setMessage,status, setStatus,
    sendNotification,setSendNotification, sendNotificationHandle,
    loadingOnSave,statusList,setInternalNote,internalNote} = props;


  return (
    <React.Fragment>
        <Grid container justifyContent={"center"}>
        <Box
          my={4}
          alignItems="center"
          gap={4}
          p={2}
          sx={{ border: '1px solid grey', width:"90%" }}
        >
           <Tiptap onUpdateHandle={e=> setMessage(e)} />
         {/* <TextareaAutosize defaultValue={message} 
                onChange={e=> setMessage(e.target.value)}  minRows={8} lang="pt-Br" style={{width:"100%"}}/> */}

        
        <FormControl sx={{width:"200px", marginRight:10}}>
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status}
            label="Status"
            onChange={e=> setStatus(e.target.value)}
          >
        {statusList &&
          statusList.map((row) => (
            <MenuItem value={row.account_requests_status_id}>{row.name}</MenuItem>
          ))
        }
          </Select>
        </FormControl>
        <FormControlLabel control={<Switch defaultChecked={sendNotification} onClick={e => setSendNotification(!sendNotification)} />} label="Enviar E-mail" />

        <FormControlLabel control={<Switch defaultChecked={internalNote} onClick={e => setInternalNote(!internalNote)} />} label="Anotação Interna" />

        <Grid container justifyContent={"center"}>
        {!loadingOnSave &&
        
          <Button variant="contained" size="large" onClick={e=> sendNotificationHandle()}>Enviar</Button>
        }
        {loadingOnSave && <Grid item>
             
             <Box sx={{ display: 'flex' }}>
               <CircularProgress />
             </Box>
             </Grid>}
        </Grid>

        </Box>

                </Grid>

     
      <Divider my={6} />
  
       
    </React.Fragment>
  );
}

export default TicketViewReply;
