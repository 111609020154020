import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";
import AuthGuardBS from "./components/guards/AuthGuardBS";

// Auth components
import SignIn from "./pages/auth/SignIn";
import ResetPassword from "./pages/auth/ResetPassword";
import ResetPasswordStep2 from "./pages/auth/ResetPasswordStep2"
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Protected routes
import BSAccountList from "./pages/BSAccount/lista"
import BSAccountListPending from "./pages/BSAccount/list-pending"
import BSAccountApproveAccount from "./pages/BSAccount/aprove-account"
import BSAccountDetailsAccount from "./pages/BSAccount/details"


import ConformidadeConsultaRestritiva from "./pages/Conformidade/consulta-lista-restritiva"
import ConformidadeConsultaCPFCNPJ from "./pages/Conformidade/consulta-cpf-cnpj"
import TicketLists from "./pages/Tickets/lista";
import TicketView from "./pages/Tickets/edit";


// Dashboard components
const Default = async(() => import("./pages/dashboard"));

const routes = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <SignIn />,
      },
      {
        path: "sign-in/",
        element: <SignIn />,
      },
      {
        path: "sign-in/:resetedPassword",
        element: <SignIn />,
      },

      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "reset-password/:email/",
        element: <ResetPasswordStep2 />,
      },
      {
        path: "reset-password/:email/:token",
        element: <ResetPasswordStep2 />,
      },

     
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "digital-account",
    element: (
      <AuthGuardBS>
        <DashboardLayout />
      </AuthGuardBS>
    ),
    children: [
      {
        path: "bs-account",
        element: <BSAccountList />,
      },
      {
        path: "bs-account/lista",
        element: <BSAccountList />,
      },
      {
        path: "bs-account/lista/pendentes",
        element: <BSAccountListPending />,
      },
      {
        path: "bs-account/aprovacao/:bsaccountid",
        element: <BSAccountApproveAccount />,
      },
      {
        path: "bs-account/details/:bsaccountid",
        element: <BSAccountDetailsAccount />,
      },
      {
        path: "conformidade/lista-restritiva",
        element: <ConformidadeConsultaRestritiva />,
      },
      {
        path: "conformidade/consulta-cpf-cnpj",
        element: <ConformidadeConsultaCPFCNPJ />,
      },
    ],
  },
  {
    path: "digital-account/tickets",
    element: (
      <AuthGuardBS>
        <DashboardLayout />
      </AuthGuardBS>
    ),
    children: [
      {
        path: "",
        element: <TicketLists />,
      },
      {
        path: "id/:id",
        element: <TicketView />,
      }
    ],
  },
  
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
