import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function ResetPassword() {
  let { email,token } = useParams();


  const navigate = useNavigate();
  const { resetPasswordStep2 } = useAuth();

  return (
    <Formik
      initialValues={{
        email: email,
        token: token!=0?token:null,
        password:null,
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("O e-mail deve ser válido.")
          .max(255)
          .required("O e-mail deve ser preenchido."),

          token: Yup.string("O token deve ser preenchido")
          .max(255)
          .required("O token deve ser preenchido."),

          password: Yup.string("A senha deve ser preenchido")
          .max(255)
          .required("O a senha deve ser preenchido."),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await resetPasswordStep2(values.email,values.password,values.token);
          navigate("/sign-in/reseted");
        } catch (error) {
          console.log(error);
          const message = error.message["error"] || "Um erro ocorreu. Por favor, tente novamente.";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label="Seu e-mail"
            disabled={true}
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
          autoComplete="off"
            type="text"
            name="token"
            label="Código recebido"
            value={values.token}
            error={Boolean(touched.token && errors.token)}
            fullWidth
            helperText={touched.token && errors.token}
            onBlur={handleBlur}
            onChange={handleChange}
            inputProps={{
              autocomplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
            my={3}
          />
            <TextField
              type="password"
              name="password"
              label="Informe sua nova senha"
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
          />
          
          
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Recuperar a senha
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default ResetPassword;
