import React, { useEffect,useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  LinearProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import InputMask from "react-input-mask";


const Divider = styled(MuiDivider)(spacing);



function TicketViewBody({body}) {

  return (
    <React.Fragment>
    <Box component="section" sx={{ border: '1px dashed grey' }}>
      <Grid container>
      
        <Box
          height={100}
          width={300}
          my={4}
          display="flex"
          alignItems="center"
          gap={4}
          p={2}
          sx={{ border: '2px solid grey' }}
        >
        {body.operator.id &&
        <React.Fragment>
          <Typography variant="caption">
          <strong>Operador:</strong>
          {body.operator.fullname} <br/>
          CPF : {body.operator.email} <br/>
          E-mail : {body.operator.email} <br/>
          Data: {
          new Date(body.created_at).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'})+
          " "+new Date(body.created_at).toLocaleTimeString('pt-br',{style: 'currency', currency: 'BRL'})
          } <br/>
        
        Interno : {body.internal?'Sim':"Não"} <br/>
          </Typography>
        </React.Fragment>
        }


        {body.sysadm.consultant &&
        <React.Fragment>
          <Typography variant="caption">
          <strong>Consultor:   {body.sysadm.consultant} </strong>
        <br/>
          Data: {
          new Date(body.created_at).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'})+
          " "+new Date(body.created_at).toLocaleTimeString('pt-br',{style: 'currency', currency: 'BRL'})
          } <br/>
        
        Interno : {body.internal?'Sim':"Não"} <br/>
          </Typography>
        </React.Fragment>
        }
        


       
        
        
        </Box>
        

        <Box sx={{ p: 2, border: '0px dashed grey' }} >
         <div dangerouslySetInnerHTML={{__html: body.text }}/> 
         {/* <div class='new-line'  style={{whiteSpace: "pre-wrap"}}>
          { body.text }
        </div> */}

        {body.attachments &&
          <React.Fragment>
          <span>Anexos:</span>
          {
            body.attachments.map((row) => (
              <React.Fragment>
                <Link  href={row.url} target="_blank">
                {row.filename}
                </Link> |  
              </React.Fragment>
            ))
          }
          </React.Fragment>
        }
       

        </Box>
     
      </Grid>
      
      
      </Box>
      <Divider my={6} />
  
       
    </React.Fragment>
  );
}

export default TicketViewBody;
