import React, { useEffect,useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  LinearProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import InputMask from "react-input-mask";
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);




function DataGridClientes() {
let { getApi,admPostApi,admGetApi } = useAuth();
const [result_cpf_cnpj, setresult_cpf_cnpj] = React.useState([]);
const [loadingList, setLoadingList] = React.useState(false);





const [limitPage, setLimitPage] = useState(5);



const [searchText, setSearchText] = useState(null);


useEffect(() => {

},[]);







const LoadMyClients = async (page_arg=null, limit_arg=null) => {
  if(searchText==null){
    return ;
  }
  setresult_cpf_cnpj([]);
  setLoadingList(true);
  console.log(page_arg)

  let response = await getApi("/consult/cpf_cnpj/"+searchText, {});
  console.log(response)
  
  setresult_cpf_cnpj(response);
  setLoadingList(false);

};


const PrettyPrintJson = ({data}) => (<div><pre>{JSON.stringify(data, null, 2) }</pre></div>);


  return (
    <Card mb={6}>
      <CardContent pb={1}>
  
       
      </CardContent>
      <Typography variant="h6" gutterBottom>
          Procurar
        </Typography>
            <Grid container spacing={6}>
                <Grid item md={6}>
                  <TextField
                    id="query"
                    placeholder={"Campo de busca"}
                    variant="outlined"
                    fullWidth
                    my={2}
                    type="text"
                    value={searchText}
                    onChange={e=> setSearchText(e.target.value)}
                  />
                </Grid>
                  
               
              </Grid>


              <Button variant="contained"  color="primary" mt={3} onClick={e => LoadMyClients(0)}>
                Pesquisar
              </Button>
              

              <Divider my={6} />
      <Paper>
     
        <h5>Resultado</h5>
        <PrettyPrintJson data={ result_cpf_cnpj } />
          

      </Paper>
    </Card>
  );
}

function DataGridPage() {
  const [open, setOpen] = React.useState(false);
const [selectedValue, setSelectedValue] = React.useState([]);

const handleClose = (value) => {
  ShowModalFunc(null)
};

let { getApi,postApi,ShowModalFunc,showModal,isInitializedBS } = useAuth();




  return (
    <React.Fragment>
      <Helmet title="Contas de Negócios" />
      <Grid justifyContent="space-between" container spacing={10}>
      <Grid item>
      <Typography variant="h3" gutterBottom display="inline">
       Consulta de CPF/CNPJ
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/cobrancas">
       CPF e CNPJ

        </Link>
        <Typography>Consulta</Typography>
      </Breadcrumbs>
      </Grid>
      
      
        </Grid>


      <Divider my={6} />
  
      <DataGridClientes />
     

       
       
    </React.Fragment>
  );
}

export default DataGridPage;
