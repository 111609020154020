import React, { useEffect,useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  LinearProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import InputMask from "react-input-mask";
import TicketViewBody from "./body";
import TicketViewReply from "./reply";
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);






function TicketView(props) {
  let { getApi,admPostApi,admGetApi,admPutApi } = useAuth();

  let { id } = useParams();

  const [accountRequest, setAccountRequest] = useState([]);
  const [message, setMessage] = useState("");
  
  const [status, setStatus] = useState("");
  const [sendNotification, setSendNotification] = useState(true);
  const [internalNote, setInternalNote] = useState(false);
  const [statusList, setStatusList] = useState([]);

  
  const [loadingOnSave, setLoadingOnSave] = useState(false);

  const LoadReq = async () => {
    
    let response = await admGetApi("/tickets/id/"+id,{ });
    setAccountRequest(response.ticket);
    setStatus(response.ticket.status.id)
  };

  const LoadStatus = async () => {
    
    let response = await admGetApi("/tickets/status",{ });
    setStatusList(response);
  };

  
  useEffect(() => {
    LoadStatus();
    LoadReq();
  },[id]);

  const sendNotificationHandle = async () => {
    setLoadingOnSave(true);
    let response = await admPutApi("/tickets/id/"+id,{
      text:message,
      status:status,
      sendNotification:sendNotification,
      internal:internalNote
     });
     alert("Mensagem enviada com sucesso.");
    setAccountRequest(response.ticket);
    setLoadingOnSave(false)
  };



  return (
    <React.Fragment>
      <Helmet title="Contas de Negócios" />
      <Grid justifyContent="space-between" container spacing={10}>
      <Grid item>
      <Typography variant="h3" gutterBottom display="inline">
       Ticket  - <small>{accountRequest.status?accountRequest.status.name:null}</small>
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/digital-account/tickets">
        Tickets
        </Link>
        <Typography>#</Typography>
      </Breadcrumbs>
      </Grid>
      
      
      
        </Grid>

       <Grid container justifyContent={"center"}>
       {accountRequest.id &&
        <Box
          height={120}
          my={4}
          display="flex"
          alignItems="center"
          gap={4}
          p={2}
          sx={{ border: '1px solid grey', width: "25%" }}
        >Ticket
       ID: {accountRequest.id} <br/>
       Assunto: {accountRequest.subject} <br/>
       Prioridade: {accountRequest.priority} <br/>
       Tipo: {accountRequest.type.name} <br/>
       Status: {accountRequest.status.name} <br/>
        </Box>
       }
       

      {accountRequest.bsaccount &&
        <Box
          height={120}
          my={4}
          display="flex"
          alignItems="center"
          gap={4}
          p={2}
          sx={{ border: '1px solid grey', width: "30%" }}
        >
       ID: {accountRequest.bsaccount.id} <br/>
       Razão Social: {accountRequest.bsaccount.company_name} <br/>
       Nome Fantasia: {accountRequest.bsaccount.fantasy_name} <br/>
       CPF/CNPJ: {accountRequest.bsaccount.identifier} <br/>
       Consultor: {accountRequest.bsaccount.consultant} <br/>
        </Box>
      }
       

      {accountRequest.operator &&
      
        <Box
          height={120}
          my={4}
          display="flex"
          alignItems="center"
          gap={4}
          p={2}
          sx={{ border: '1px solid grey', width: "30%" }}
        >
       Operador: {accountRequest.operator.fullname} <br/>
       E-mail: {accountRequest.operator.email} <br/>
       CPF/CNPJ: {accountRequest.operator.personal_tax_identifier} <br/>
       Telefone: {accountRequest.operator.phone} <br/>
        </Box>
      }
       
       </Grid>
      <Divider my={3} />
      {!accountRequest.id && <Grid item>
             
             <Box sx={{ display: 'flex' }}>
               <CircularProgress />
             </Box>
             </Grid>}

      {accountRequest.id &&
        statusList &&
        <TicketViewReply
        loadingOnSave={loadingOnSave} 
        statusList={statusList}
            message={message}
            setMessage={setMessage}
            status={status}
            setStatus={setStatus}
            sendNotification={sendNotification}
            setSendNotification={setSendNotification}
            sendNotificationHandle={sendNotificationHandle}
            internalNote={internalNote}
            setInternalNote={setInternalNote}


            />
      }
      

      <Divider my={6} />
      {!accountRequest.id && <Grid item>
             
             <Box sx={{ display: 'flex' }}>
               <CircularProgress />
             </Box>
             </Grid>}
      <Typography variant="h4">
        Respostas
      </Typography>
    
    {    accountRequest.body && accountRequest.body.map((body) => (
          <TicketViewBody key={body.id} body={body}/>
    ))
      }
     

       
       
    </React.Fragment>
  );
}

export default TicketView;
