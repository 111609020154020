import React, { useEffect,useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  LinearProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import InputMask from "react-input-mask";
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);




function DataGridClientes() {
let { getApi,admPostApi,admGetApi } = useAuth();
const [listCobr, setListCobr] = React.useState([]);
const [loadingList, setLoadingList] = React.useState(false);





const [page, setPage] = useState(0);
const [limitPage, setLimitPage] = useState(5);
const [totalRecords, setTotalRecords] = useState(0);



const [arrayResult, setArrayResult] = React.useState([]);




const navigate = useNavigate();


const columns = [
  { field: "id", headerName: "ID", width: 120,
  renderCell: (params: GridRenderCellParams<Date>)  => (
    <React.Fragment>
       <Link
         >
          {params.row.id?params.row.id:null}
        </Link>
    </React.Fragment>
  
      
  ) },
  {
    field: "source_name",
    headerName: "Fonte",
    sortable: false,
    width: 200,
  },
  {
    field: "identification_name",
    headerName: "Nome",
    sortable: false,
    width: 390,
  },
  {
    field: "identification_id",
    headerName: "Identificação",
    sortable: false,
    width: 330,
  },
  {
    field: "obs",
    headerName: "OBS",
    sortable: false,
    width: 380,
  }
];




const [searchText, setSearchText] = useState(null);
const [query_type, setQueryType] = useState("name");


useEffect(() => {


  LoadMyClients(0, 5);
},[]);







const LoadMyClients = async (page_arg=null, limit_arg=null) => {
  if(searchText==null){
    return ;
  }
  setListCobr([]);
  setLoadingList(true);
  console.log(page_arg)

  let response = await getApi("/restrict/list/"+query_type+"/"+searchText, {});
  console.log(response.lista)
  
  setPage(response.page);
  setLimitPage(response.limit);
  setTotalRecords(response.total)
  setListCobr(response.lista);
  setLoadingList(false);

};




  return (
    <Card mb={6}>
      <CardContent pb={1}>
  
       
      </CardContent>
      <Typography variant="h6" gutterBottom>
          Procurar
        </Typography>
            <Grid container spacing={6}>
                <Grid item md={6}>
                  <TextField
                    id="query"
                    placeholder={"Campo de busca"}
                    variant="outlined"
                    fullWidth
                    my={2}
                    type="text"
                    value={searchText}
                    onChange={e=> setSearchText(e.target.value)}
                  />
                </Grid>
                <Grid item md={4}>
                  
                <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Tipo de pesquisa</InputLabel>
                      <Select fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={"Tipo de pesquisa"}
                        value={query_type}
                        onChange={e => setQueryType(e.target.value)}>
                          <MenuItem value={"name"}>Nome</MenuItem>
                          <MenuItem value={"identification"}>Identificação</MenuItem>
                      </Select>
                    </FormControl>
                   
                </Grid>
              </Grid>


              <Button variant="contained"  color="primary" mt={3} onClick={e => LoadMyClients(0)}>
                Pesquisar
              </Button>
              

              <Divider my={6} />
      <Paper>
        <div style={{ height: (limitPage==5?400:600), width: "100%" }}>
     

          <DataGrid
            rowsPerPageOptions={[5, 10, 25]}
            rows={listCobr}
            columns={columns}
            pageSize={limitPage}
            rowCount={totalRecords}
            page={page}
            onPageSizeChange={e  => {LoadMyClients(null, e)  } }
            paginationMode={'server'}
            onPageChange={e  => {LoadMyClients(e, null)  } }
            loading={loadingList}
            
          />
          

        </div>
      </Paper>
    </Card>
  );
}

function DataGridPage() {
  const [open, setOpen] = React.useState(false);
const [selectedValue, setSelectedValue] = React.useState([]);

const handleClose = (value) => {
  ShowModalFunc(null)
};

let { getApi,postApi,ShowModalFunc,showModal,isInitializedBS } = useAuth();




  return (
    <React.Fragment>
      <Helmet title="Contas de Negócios" />
      <Grid justifyContent="space-between" container spacing={10}>
      <Grid item>
      <Typography variant="h3" gutterBottom display="inline">
        Lista Restritiva
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/cobrancas">
        Lista Restritiva

        </Link>
        <Typography>Consulta</Typography>
      </Breadcrumbs>
      </Grid>
      
      
        </Grid>


      <Divider my={6} />
  
      <DataGridClientes />
     

       
       
    </React.Fragment>
  );
}

export default DataGridPage;
